import React, { FunctionComponent } from "react";
import classNames from "classnames";

import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";

import "./styles.scss";

interface PictureProps {
  image: ImageInterface;
  options: {
    sources?: {
      media: string;
      width: number;
      height: number;
    }[];
    width: number;
    height: number;
    modifiers?: string[];
  };
}

const Picture: FunctionComponent<PictureProps> = ({ image, options }) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const createClassModifiers = (modifiers: string[]) => {
    if (!modifiers) {
      return "";
    }
    return modifiers.map((modifier: string) => `c-picture--${modifier}`);
  };

  return (
    <picture className={classNames("c-picture", options.modifiers && createClassModifiers(options.modifiers))}>
      {options.sources?.map(({ media, width, height }, i) => (
        <source
          key={i}
          media={media}
          srcSet={`${urlBuilder
            .image(image as ImageInterface)
            .quality(80)
            .auto("format")
            .width(width)
            .height(height)}`}
        />
      ))}

      <img
        src={
          urlBuilder
            .image(image as ImageInterface)
            .auto("format")
            .quality(100)
            .width(options.width)
            .height(options.height)
            .url() as string
        }
        alt={image?.alt}
        loading={"lazy"}
        width={options.width + "px"}
        height={options.height + "px"}
      />
      {image?.caption && <caption className="c-picture__image-caption">{image?.caption}</caption>}
    </picture>
  );
};
export default Picture;
