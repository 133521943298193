import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { ImageInterface } from "../../types/SanityTypes";
import Picture from "../Picture";
import useMediaQuery from "../../hooks/useMediaQuery";
import RichText from "../RichText";
import Section from "../Section/index";
import SocialWebsite from "../../images/icons/social-website";
import SocialInstagram from "../../images/icons/social-instagram";
import { Link } from "../Link";

import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

export type PersonType = {
  name: string;
  company: string;
  designation: string;
  isDesignationLink: boolean;
  isDesignationExternalLink: boolean;
  designationExternalLink: string;
  designationCtaLink: {
    slug: {
      current: string;
    };
  };
  program: string;
  programWithYear: string;
  socials: {
    platform: string;
    link: string;
    screenReaderText?: string;
  }[];
  image: ImageInterface;
  sanityImage: ImageInterface;
  companyLogo: ImageInterface;
};

interface PersonTypeWithModifiers extends PersonType {
  alignment?: string;
  caseStudyBadge: {
    icon: ImageInterface;
    label: string;
  };
}

interface CaseStudyInterface {
  name: string;
  caseStudyContent: any;
  heading: string;
  theme: {
    bgColor: {
      title: string;
    };
  };
  person: PersonType;
  alignment: string;
  caseStudyBadge: {
    icon: ImageInterface;
    label: string;
  };
}

const PersonDetailsComponent = ({
  program,
  name,
  designation,
  isDesignationLink,
  isDesignationExternalLink,
  designationExternalLink,
  designationCtaLink,
  socials,
  alignment,
  caseStudyBadge
}: PersonTypeWithModifiers) => {
  const SocialListRenderer = ({
    platform,
    link,
    screenReaderText
  }: {
    platform: string;
    link: string;
    screenReaderText?: string;
  }) => {
    const SocialPlatformIconsMap: { [key: string]: FunctionComponent } = {
      Instagram: SocialInstagram,
      Website: SocialWebsite
    };

    return (
      <li>
        <a href={link} rel="noreferrer" className="social-link">
          <span className="sr-only">{platform}</span>
          {React.createElement(SocialPlatformIconsMap[platform])}
          {platform == "Instagram" && screenReaderText ? (
            <span className="sr-only">{screenReaderText}</span>
          ) : (
            <span className="social-address">{link}</span>
          )}
        </a>
      </li>
    );
  };

  return (
    <div className="c-case-study__person-details-wrapper">
      <div className="c-case-study__person-details">
        {caseStudyBadge && (
          <p className="c-case-study__badge">
            <Picture
              image={caseStudyBadge.icon}
              options={{
                width: 16,
                height: 16
              }}
            />
            <span>{caseStudyBadge.label}</span>
          </p>
        )}
        {alignment === "right" ? (
          <h2 className="c-case-study__person-name">{name}</h2>
        ) : (
          <h3 className="c-case-study__person-name">{name}</h3>
        )}
        {program && <p>{program}</p>}
        {designation && isDesignationLink ? (
          <p>
            <Link
              className="c-case-study__person-link"
              to={designationExternalLink || designationCtaLink?.slug?.current || "/"}
              isExternalLink={isDesignationExternalLink}
            >
              {designation}
            </Link>
          </p>
        ) : (
          <p>{designation}</p>
        )}
      </div>
      <ul className="c-case-study__person-socials flow-content">
        {socials.map((social: { platform: string; link: string; screenReaderText?: string }, i: number) => (
          <SocialListRenderer key={i} {...social} />
        ))}
      </ul>
    </div>
  );
};

const CaseStudy: FunctionComponent<CaseStudyInterface> = ({
  caseStudyContent,
  theme,
  heading,
  person,
  alignment,
  caseStudyBadge
}) => {
  const matches = useMediaQuery("(max-width: 768px)");
  const sanityImage = person.sanityImage;

  return (
    <Section sectionClassName="c-case-study" theme={theme?.bgColor?.title} title={alignment === "left" ? heading : ""}>
      <div className="c-case-study__wrapper">
        <div className="flow-content">
          {(alignment === "left" || matches) && (
            <PersonDetailsComponent caseStudyBadge={caseStudyBadge} {...person} alignment={alignment} />
          )}
          <div className="c-case-study__image-wrapper">
            <SanityImage
              {...sanityImage}
              width={645}
              height={483}
              htmlWidth={645}
              htmlHeight={483}
              sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK}
              className="c-case-study__image--rounded"
            />
          </div>
        </div>
        <div className="c-case-study__content">
          <div className="c-case-study__person">
            {alignment === "right" && !matches && (
              <PersonDetailsComponent caseStudyBadge={caseStudyBadge} {...person} alignment={alignment} />
            )}
          </div>
          <RichText data={caseStudyContent} />
        </div>
      </div>
    </Section>
  );
};

export default CaseStudy;
