import * as React from "react";
const SocialInstagram = props => (
  <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.522.087C10.068.016 10.562 0 14.5 0c3.939 0 4.432.017 5.977.087 1.544.07 2.6.316 3.522.674a7.134 7.134 0 0 1 2.568 1.674A7.082 7.082 0 0 1 28.238 5c.359.923.604 1.977.675 3.52.071 1.549.087 2.042.087 5.979 0 3.939-.017 4.432-.087 5.978-.07 1.542-.316 2.597-.675 3.52a7.111 7.111 0 0 1-1.671 2.569 7.11 7.11 0 0 1-2.568 1.671c-.923.359-1.977.604-3.52.675-1.547.071-2.04.087-5.979.087s-4.432-.017-5.978-.087c-1.542-.07-2.597-.316-3.52-.675a7.115 7.115 0 0 1-2.569-1.671 7.107 7.107 0 0 1-1.672-2.568c-.358-.923-.603-1.977-.674-3.52C.016 18.93 0 18.437 0 14.5c0-3.939.017-4.432.087-5.977.07-1.545.316-2.6.674-3.522a7.113 7.113 0 0 1 1.674-2.568A7.108 7.108 0 0 1 5 .761C5.924.403 6.978.158 8.521.087h.001Zm11.837 2.61c-1.529-.07-1.988-.084-5.859-.084s-4.33.014-5.86.084c-1.414.065-2.18.3-2.692.5A4.503 4.503 0 0 0 4.28 4.28a4.498 4.498 0 0 0-1.083 1.668c-.2.511-.435 1.278-.5 2.693-.07 1.529-.084 1.988-.084 5.859 0 3.872.014 4.33.084 5.86.065 1.414.3 2.18.5 2.692.232.63.602 1.2 1.083 1.668a4.501 4.501 0 0 0 1.668 1.083c.511.2 1.278.435 2.693.5 1.529.07 1.986.084 5.859.084s4.33-.014 5.86-.084c1.414-.065 2.18-.3 2.692-.5a4.502 4.502 0 0 0 1.668-1.083 4.502 4.502 0 0 0 1.083-1.668c.2-.511.435-1.278.5-2.693.07-1.529.084-1.988.084-5.859s-.014-4.33-.084-5.86c-.065-1.414-.3-2.18-.5-2.692A4.502 4.502 0 0 0 24.72 4.28a4.5 4.5 0 0 0-1.668-1.083c-.511-.2-1.278-.435-2.693-.5ZM12.648 18.97a4.837 4.837 0 1 0 3.482-9.024 4.838 4.838 0 0 0-3.482 9.024ZM9.23 9.23a7.453 7.453 0 1 1 10.54 10.54A7.453 7.453 0 0 1 9.23 9.23Zm14.376-1.073a1.763 1.763 0 1 0-2.42-2.565 1.763 1.763 0 0 0 2.42 2.565Z"
      style={{ fill: "currentcolor" }}
    />
  </svg>
);
export default SocialInstagram;
