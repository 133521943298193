import * as React from "react";
const SocialWebsite = props => (
  <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5 0c1.331 0 2.615.17 3.852.51 1.236.34 2.393.826 3.469 1.458a14.564 14.564 0 0 1 2.931 2.266c.878.878 1.633 1.86 2.266 2.945a14.604 14.604 0 0 1 1.458 3.47A14.76 14.76 0 0 1 29 14.5c0 1.331-.17 2.615-.51 3.852a14.282 14.282 0 0 1-1.458 3.469 14.562 14.562 0 0 1-2.266 2.931 14.392 14.392 0 0 1-2.945 2.266 14.604 14.604 0 0 1-3.47 1.458A14.76 14.76 0 0 1 14.5 29c-1.331 0-2.615-.17-3.852-.51a14.28 14.28 0 0 1-3.469-1.458 14.56 14.56 0 0 1-2.931-2.266 14.39 14.39 0 0 1-2.266-2.945 14.664 14.664 0 0 1-1.458-3.455A14.703 14.703 0 0 1 0 14.5c0-1.331.17-2.615.51-3.852.34-1.236.826-2.393 1.458-3.469a14.563 14.563 0 0 1 2.266-2.931 14.391 14.391 0 0 1 2.945-2.266A14.665 14.665 0 0 1 10.634.524 14.703 14.703 0 0 1 14.5 0Zm0 27.188c1.161 0 2.28-.151 3.356-.454a13.036 13.036 0 0 0 3.03-1.274 12.248 12.248 0 0 0 2.577-1.997 13.215 13.215 0 0 0 1.983-2.563 12.79 12.79 0 0 0 1.288-3.03c.312-1.095.463-2.218.453-3.37 0-1.161-.15-2.28-.453-3.356a13.036 13.036 0 0 0-1.274-3.03 12.248 12.248 0 0 0-1.997-2.577A13.217 13.217 0 0 0 20.9 3.554a12.79 12.79 0 0 0-3.03-1.288 11.897 11.897 0 0 0-3.37-.454c-1.161 0-2.28.152-3.356.454a13.034 13.034 0 0 0-3.03 1.274 12.244 12.244 0 0 0-2.577 1.997A13.214 13.214 0 0 0 3.554 8.1a12.79 12.79 0 0 0-1.288 3.03 11.897 11.897 0 0 0-.454 3.37c0 1.161.152 2.28.454 3.356a13.034 13.034 0 0 0 1.274 3.03 12.245 12.245 0 0 0 1.997 2.577A13.212 13.212 0 0 0 8.1 25.446c.925.547 1.935.977 3.03 1.288 1.095.312 2.218.463 3.37.453Zm8.454-12.461.68-2.04h1.061l-1.203 3.626H22.43l-.68-2.04-.68 2.04h-1.062l-1.203-3.625h1.062l.68 2.039.68-2.04h1.047l.68 2.04Zm-6.57-2.04h1.061l-1.203 3.626H15.18l-.68-2.04-.68 2.04h-1.062l-1.203-3.625h1.062l.68 2.039.68-2.04h1.047l.68 2.04.68-2.04Zm-7.25 0h1.061l-1.203 3.626H7.93l-.68-2.04-.68 2.04H5.508l-1.203-3.625h1.062l.68 2.039.68-2.04h1.047l.68 2.04.68-2.04Z"
      style={{ fill: "currentcolor" }}
    />
  </svg>
);
export default SocialWebsite;
